import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <Box sx={{ bgcolor: '#31257B' }}>
            <Grid container spacing={2}>
                <Grid item md={4} lg={4} sm={6} xs={12}>
                    <Box sx={{ width: '100%', height: 260, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <img src="./images/assets/img/bottom.png" alt="logo" style={{ width: '100%', height: '150px', objectFit: 'contain' }} />
                    </Box>
                </Grid>
                <Grid item md={4} lg={4} sm={6} xs={12}>
                    <Box sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', }}>
                        <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 25 }}>About SK Techrise</Typography>
                        <Typography sx={{ color: 'white' }}>"SK Techrise is a trusted partner in turning your vision into reality. We specialize in developing tailored digital solutions, from mobile apps to full-scale web platforms. Let us help you grow with cutting-edge technology and innovative digital strategies. Contact us today to take your business to the next level."</Typography>
                        <List sx={{ listStyleType: 'disc', paddingLeft: 2, color: 'white', my: 1 }}>
                            <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 300, color: 'white' }}>Services: Android | iOS | Web Development | eCommerce | WooCommerce | Digital Marketing</Typography>} />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 300, color: 'white' }}>© 2024 SK Techrise. All Rights Reserved.</Typography>} />
                            </ListItem>
                            <Link to={'#'} style={{ textDecoration: 'none', color: 'white' }} >
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Contact Us | Privacy Policy</Typography>} />
                                </ListItem>
                            </Link>
                        </List>
                    </Box>
                </Grid>
                <Grid item md={4} lg={4} sm={6} xs={12}>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography sx={{ color: 'white' }}>Contact Us</Typography>

                        <List sx={{ listStyleType: 'disc', paddingLeft: 2, color: 'white' }}>
                            <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Email: sktechrise@gmail.com</Typography>} />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Mobile No.: +91 82105 74144, +91 62013 42801</Typography>} />
                            </ListItem>
                            {/* <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Mobile No.: +91 62013 42801</Typography>} />
                            </ListItem> */}
                            <Link to={'https://www.linkedin.com/in/sk-techrise-74140b317/'} style={{ textDecoration: 'none', color: 'white' }} target='_blank'>
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>LinkedIn: SK Techrise</Typography>} />
                                </ListItem>
                            </Link>
                        </List>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}

export default Footer