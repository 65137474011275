import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

function Home() {
    return (
        <Box sx={{ height: '70vh', px: 4 }}>
            <Card sx={{ bgcolor: '#31257B', width: '100%', height: '70vh', borderRadius: '30px', display: 'flex' }}>
                <Box sx={{ flexGrow: 1, width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                    <CardContent sx={{ pl: 3, display: 'flex', flexDirection: 'column', py: 1 }}>
                        <Typography sx={{ color: 'white', fontSize: 50, fontWeight: 'bold' }}>Website & Mobile App Design And Development Company</Typography>
                        <Typography sx={{ color: 'white', fontSize: 30 }}>Enjoy Every Good Moment</Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', }}>
                        <Button variant="contained" sx={{ ml: 2, bgcolor: '#019BE2', }}>Get in touch</Button>
                        <Button variant="contained" sx={{ ml: 2, bgcolor: '#019BE2', }}>Get in touch</Button>
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, width: '50%', display: { md: 'flex', lg: 'flex', sm: 'none', xs: 'none' }, p: 2, alignItems: 'center', justifyContent: 'center', }}>
                    <CardMedia component="img" sx={{ width: '100%' }} image={"./images/assets/img/asset.png"} alt="Live from space album cover" />

                </Box>
            </Card>
        </Box>
    )
}

export default Home