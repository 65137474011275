import { Box } from '@mui/material'
import React from 'react'
import ServiceSection from '../common/ServiceSection'

function Services() {
    return (
        <Box sx={{ px: 4, my: 3 }}>
            <ServiceSection />
        </Box>
    )
}

export default Services