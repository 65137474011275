import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PhoneIcon from '@mui/icons-material/Phone';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import { Link } from 'react-router-dom';
import { Link } from 'react-scroll';

const Logo = styled('img')({
    height: '40px',
    position: 'absolute'
});


{/* <Link to="home" smooth={true} duration={500}>
                            Home
                        </Link> */}


const Header = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navItems = ['Home', 'About Us', 'Service', 'Gallery', 'Contact Us'];

    const drawer = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                {navItems.map((text) => (
                    <Link to={text} smooth={true} duration={500} style={{ textDecoration: 'none' }}>
                        <ListItem button key={text}>
                            <ListItemText primary={text} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <AppBar position="sticky" elevation={0} sx={{ backgroundColor: 'white', top: 0, transition: 'box-shadow 0.3s ease-in-out', boxShadow: scrolled ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none', }}>
                <Toolbar sx={{ justifyContent: 'space-between', px: { xs: 2, sm: 4 }, py: 1 }}>
                    {/* Left Section: Logo */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Logo src={'./images/assets/img/logo.png'} alt="Logo" />
                        {/* <Typography variant="h6" color="textPrimary" sx={{ ml: 16, display: { xs: 'none', md: 'block' } }}>Shadih Vivaah</Typography> */}
                    </Box>


                    {/* right Section: Navigation */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
                        {navItems.map((item) => (
                            <Link to={item} smooth={true} duration={500} offset={-100} style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                <Typography key={item} color="#2E2D81" sx={{
                                    fontWeight: 700, '&:hover': {
                                        textDecoration: 'none',
                                    },
                                }}>{item}</Typography>
                            </Link>
                        ))}
                    </Box>

                    {/* Mobile Menu Button */}
                    <IconButton color="black" sx={{ display: { md: 'none' }, ml: 'auto' }} onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>

                    {/* Right Section: Contact and Apply Now */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {/* <ButtonCustom /> */}
                        <Button variant="contained" sx={{ ml: 2, bgcolor: '#019BE2', display: { xs: 'none', md: 'block' } }}>Get in touch</Button>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Drawer for Mobile Menu */}
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawer}
            </Drawer>
        </>
    );
};

export default Header;

