import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Typography, IconButton, Card, CardMedia, CardContent } from '@mui/material';
import { animateScroll as scroll } from 'react-scroll';

const ScrollProgressIndicator = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollPercent = (scrollTop / docHeight) * 100;
            setScrollProgress(scrollPercent);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        scroll.scrollToTop({
            duration: 500, // Duration in milliseconds
            smooth: true,  // Smooth scrolling
        });
    };


    return (
        <Box sx={{ position: 'fixed', bottom: 30, right: 30, zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', borderRadius: '50%', width: 80, height: 80, }}>
            <Box sx={{ position: 'fixed', bottom: 30, right: 30, zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', borderRadius: '50%', width: 80, height: 80, }}>
                <IconButton onClick={handleScrollToTop}>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" value={scrollProgress} size={70} thickness={5}
                            sx={{
                                color: '#31257B', // Custom color for progress
                                transform: 'rotate(-90deg)',
                            }}
                        />
                        <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                            <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: 16 }}>{`${Math.round(scrollProgress)}%`}</Typography>
                        </Box>
                    </Box>
                </IconButton>
            </Box>
        </Box>
    );
};

export default ScrollProgressIndicator;


/* 
<Card
            sx={{
                mx: 1, my: 1, border: '1px solid #31257B', borderRadius: '20px', position: 'relative', overflow: 'hidden',
                '&:hover .overlay': { // Targeting the content with class .overlay on hover
                    opacity: 1,
                    transform: 'translateY(0)',
                },
            }}
        >
            <CardMedia component="img" sx={{ width: '100%', objectFit: 'contain', height: 150, }} image={item?.logo} alt={item?.name} />
            <CardContent
                className="overlay" // Custom class to identify this content
                sx={{
                    opacity: 0, // Initially hidden
                    height: 10,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                    color: 'white', // Text color
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'opacity 0.4s ease, transform 0.4s ease', // Smooth transition for opacity and transform
                    transform: 'translateY(100%)', // Initially move it outside the view
                }}
            >
                <Typography variant="caption">{item?.name}</Typography>
            </CardContent>
        </Card>
*/