import { Box } from '@mui/material'
import React from 'react'
import HorizontalScroll from '../common/HorizontalScroll'

function ClientLogo() {
    return (
        <Box sx={{ px: 4, my: 3 }}>
            <HorizontalScroll />
        </Box>
    )
}

export default ClientLogo